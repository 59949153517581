

































import { IAppAssessment, IOnetResults } from "@/interfaces";
import { GET_USER_DETAILS } from "@/store/modules/auth/constants";
import {
  ONET_RESULT,
  SUBMIT_APP_ASSESSMENT_ANSWERS
} from "@/store/modules/candidates/constants";
import {
  OnetResultPayload,
  SubmitAssessmentAnswersApiPayload
} from "@/store/modules/candidates/interfaces";
import {
  INTERESTS_QUESTIONS_COMPLETED,
  NEXT_MOVE_ASSESSMENT_SCORES,
  NEXT_MOVE_ASSESSMENT_STATE,
  NEXT_MOVE_DATA_ERROR,
  NEXT_MOVE_DATA_LOADING,
  NEXT_MOVE_INTERESTS_QUESTIONS,
  NEXT_MOVE_QUESTION_INDEX,
  NEXT_MOVE_SCORES_ASSESSMENT_ANS,
  NEXT_MOVE_SELECTED_ASSESSMENT,
  NEXT_MOVE_SELECTED_ASSESSMENT_QUESTIONS,
  NEXT_MOVE_SELECTED_ASSESSMENT_RESULT,
  NEXT_MOVE_SELECTED_JOB_ZONE,
  RESET_MY_NEXT_MOVE_STATE
} from "@/store/modules/my_next_move/constants";
import {
  AssessmentQuestionnairesWithAns,
  MyNextMoveAssessmentStates,
  MyNextMoveScores
} from "@/store/modules/my_next_move/interfaces";
import { ASSESSMENT_RESULT } from "@/store/modules/recruiter/constants";
import { AssessmentResultApiPayload } from "@/store/modules/recruiter/interfaces";
import { ROOT_ERROR } from "@/store/modules/root/constants";
import { parseStrToInt } from "@/utils/global";
import Vue from "vue";
import { mapActions, mapGetters, mapMutations } from "vuex";

export default Vue.extend({
  name: "NextMoveFooter",

  computed: {
    ...mapGetters("auth", {
      get_user: GET_USER_DETAILS
    }),
    ...mapGetters("my_next_move", {
      assessment_state: NEXT_MOVE_ASSESSMENT_STATE,
      next_move_assessment_scores: NEXT_MOVE_ASSESSMENT_SCORES,
      assessment_scores_ans: NEXT_MOVE_SCORES_ASSESSMENT_ANS,
      next_move_selected_assessment: NEXT_MOVE_SELECTED_ASSESSMENT,
      next_move_selected_assessment_questions:
        NEXT_MOVE_SELECTED_ASSESSMENT_QUESTIONS,
      data_loading: NEXT_MOVE_DATA_LOADING,
      data_error: NEXT_MOVE_DATA_ERROR,
      interests_questions: NEXT_MOVE_INTERESTS_QUESTIONS,
      interests_questions_completed: INTERESTS_QUESTIONS_COMPLETED,
      selected_job_zone: NEXT_MOVE_SELECTED_JOB_ZONE
    }),
    NextMoveStates() {
      return MyNextMoveAssessmentStates;
    }
  },
  methods: {
    ...mapMutations("my_next_move", {
      update_assessment_state: NEXT_MOVE_ASSESSMENT_STATE,
      set_data_loading: NEXT_MOVE_DATA_LOADING,
      set_data_error: NEXT_MOVE_DATA_ERROR,
      set_selected_assessment: NEXT_MOVE_SELECTED_ASSESSMENT,
      set_selected_assessment_questions:
        NEXT_MOVE_SELECTED_ASSESSMENT_QUESTIONS,
      set_assessment_scores: NEXT_MOVE_ASSESSMENT_SCORES,
      set_assessment_scores_ans: NEXT_MOVE_SCORES_ASSESSMENT_ANS,
      set_selected_assessment_result: NEXT_MOVE_SELECTED_ASSESSMENT_RESULT,
      set_question_index: NEXT_MOVE_QUESTION_INDEX,
      set_interests_question_completed: INTERESTS_QUESTIONS_COMPLETED,
      set_interests_questions: NEXT_MOVE_INTERESTS_QUESTIONS,
      reset_next_move_state: RESET_MY_NEXT_MOVE_STATE
    }),
    ...mapActions("candidate", {
      submit_app_assessment_ans: SUBMIT_APP_ASSESSMENT_ANSWERS,
      fetch_onet_result: ONET_RESULT
    }),
    ...mapActions("recruiter", {
      assessment_result: ASSESSMENT_RESULT
    }),
    ...mapMutations({
      set_root_error: ROOT_ERROR
    }),
    // Function to process next button disable/enable state
    process_disabled() {
      if (
        this.assessment_state === MyNextMoveAssessmentStates.INTERESTS_SCORES
      ) {
        const scores = this.next_move_assessment_scores as MyNextMoveScores;
        if (scores) {
          return !(
            parseStrToInt(scores.artistic.toString()) !== null &&
            parseStrToInt(scores.conventional.toString()) !== null &&
            parseStrToInt(scores.enterprising.toString()) !== null &&
            parseStrToInt(scores.investigative.toString()) !== null &&
            parseStrToInt(scores.realistic.toString()) !== null &&
            parseStrToInt(scores.social.toString()) !== null &&
            parseInt(scores.artistic) >= 0 &&
            parseInt(scores.artistic) <= 40 &&
            parseInt(scores.conventional) >= 0 &&
            parseInt(scores.conventional) <= 40 &&
            parseInt(scores.enterprising) >= 0 &&
            parseInt(scores.enterprising) <= 40 &&
            parseInt(scores.investigative) >= 0 &&
            parseInt(scores.investigative) <= 40 &&
            parseInt(scores.realistic) >= 0 &&
            parseInt(scores.realistic) <= 40 &&
            parseInt(scores.social) >= 0 &&
            parseInt(scores.social) <= 40
          );
        }
        return false;
      }
      // If state is interest questions check all the question are answered
      else if (
        this.assessment_state === MyNextMoveAssessmentStates.INTERESTS_QUESTIONS
      ) {
        return !this.interests_questions_completed;
      } else if (
        this.assessment_state === MyNextMoveAssessmentStates.JOB_ZONES
      ) {
        return this.selected_job_zone <= 0 || this.selected_job_zone > 5;
      } else if (
        this.assessment_state === MyNextMoveAssessmentStates.MORE_CAREERS
      ) {
        return true;
      } else return false;
    },
    // Function to process next button click
    async process_next_state() {
      // If current state is start then move to start description state
      if (this.assessment_state === MyNextMoveAssessmentStates.START) {
        this.update_assessment_state(
          MyNextMoveAssessmentStates.START_DESCRIPTION
        );
      }
      // If current state is start_description then move to interest questions state
      else if (
        this.assessment_state === MyNextMoveAssessmentStates.START_DESCRIPTION
      ) {
        this.set_data_error("");
        this.set_data_loading(false);
        this.update_assessment_state(
          MyNextMoveAssessmentStates.INTERESTS_QUESTIONS
        );
      }
      // If current state is interests scores then submit assessment answer and move to next state
      else if (
        this.assessment_state === MyNextMoveAssessmentStates.INTERESTS_SCORES
      ) {
        if (!this.is_interests_scores_ans_updates()) {
          this.update_assessment_state(MyNextMoveAssessmentStates.RESULTS);
          return;
        }
        this.set_data_loading(true);
        if (
          !this.next_move_selected_assessment ||
          this.next_move_selected_assessment_questions.length <= 0
        ) {
          this.set_data_error("No assessment selected or no questions found");
          this.set_data_loading(false);
          return;
        }
        const question = this.next_move_selected_assessment_questions[0];
        // Submit interest assessment answers
        const payload: SubmitAssessmentAnswersApiPayload = {
          answer: JSON.stringify(this.next_move_assessment_scores),
          answer_type: IAppAssessment.QuestionType.JSON,
          assessment_id: this.next_move_selected_assessment.id as number,
          question_id: question.id as number,
          done: true
        };
        const res = await this.submit_app_assessment_ans(payload);
        if (!res) {
          this.set_data_error("Error submitting assessment answers");
          this.set_data_loading(false);
          return;
        }
        const scores = this.next_move_assessment_scores as MyNextMoveScores;
        // Get onet result from backend
        const codes = [
          parseInt(scores.realistic),
          parseInt(scores.investigative),
          parseInt(scores.artistic),
          parseInt(scores.social),
          parseInt(scores.enterprising),
          parseInt(scores.conventional)
        ];
        const onet_result_payload: OnetResultPayload = {
          codes
        }; // Payload to send to backend to get onet result
        const resp: IOnetResults.Root | null = await this.fetch_onet_result(
          onet_result_payload
        );
        // If response is not received then set error and stop loading
        if (!resp) {
          this.set_data_error("Failed to get assessment result");
          this.set_data_loading(false);
          return;
        }
        // If response is received then create result in the backed
        const assessment_saved = await this.save_assessment_result(resp);
        if (!assessment_saved) return;
        this.update_assessment_state(MyNextMoveAssessmentStates.RESULTS);
        this.set_data_loading(false);
      }
      // If current state is interests question then submit current pair of answer and fetch more question
      else if (
        this.assessment_state === MyNextMoveAssessmentStates.INTERESTS_QUESTIONS
      ) {
        this.set_data_error("");
        this.set_data_loading(false);
        this.process_interest_question();
      } else if (this.assessment_state === MyNextMoveAssessmentStates.RESULTS) {
        this.set_data_error("");
        this.set_data_loading(false);
        this.update_assessment_state(MyNextMoveAssessmentStates.JOB_ZONES);
      } else if (
        this.assessment_state === MyNextMoveAssessmentStates.JOB_ZONES
      ) {
        this.set_data_error("");
        this.set_data_loading(false);
        this.update_assessment_state(MyNextMoveAssessmentStates.CAREERS);
      } else if (this.assessment_state === MyNextMoveAssessmentStates.CAREERS) {
        this.set_data_error("");
        this.set_data_loading(false);
        this.update_assessment_state(MyNextMoveAssessmentStates.MORE_CAREERS);
      }
    },
    // Function to process back button click
    process_back_state() {
      // If state is start_description then move to start state
      if (
        this.assessment_state === MyNextMoveAssessmentStates.START_DESCRIPTION
      ) {
        this.reset_next_move_state();
      }
      // If state is interests scores then move to start state and reset interests scores data
      else if (
        this.assessment_state === MyNextMoveAssessmentStates.INTERESTS_SCORES
      ) {
        this.reset_next_move_state();
      }
      // If state is results then move to interests scores state/interest questions state
      else if (this.assessment_state === MyNextMoveAssessmentStates.RESULTS) {
        this.set_data_error("");
        this.set_data_loading(false);
        this.process_result_state_back();
      }
      // If state is interests questions then move to interests scores state
      else if (
        this.assessment_state === MyNextMoveAssessmentStates.INTERESTS_QUESTIONS
      ) {
        this.set_data_error("");
        this.set_data_loading(false);
        this.reset_next_move_state();
      } else if (
        this.assessment_state === MyNextMoveAssessmentStates.JOB_ZONES
      ) {
        this.set_data_error("");
        this.set_data_loading(false);
        this.update_assessment_state(MyNextMoveAssessmentStates.RESULTS);
      } else if (this.assessment_state === MyNextMoveAssessmentStates.CAREERS) {
        this.set_data_error("");
        this.set_data_loading(false);
        this.update_assessment_state(MyNextMoveAssessmentStates.JOB_ZONES);
      } else if (
        this.assessment_state === MyNextMoveAssessmentStates.MORE_CAREERS
      ) {
        this.set_data_error("");
        this.set_data_loading(false);
        this.update_assessment_state(MyNextMoveAssessmentStates.CAREERS);
      }
    },
    // Function to process back button disable/enable state
    process_back_disabled() {
      // Disable back button if state is start
      if (this.assessment_state === MyNextMoveAssessmentStates.START) {
        return true;
      }
      return false;
    },
    // Function to process result state back
    process_result_state_back() {
      if (
        this.next_move_selected_assessment.assessment_type ===
        IAppAssessment.List.MY_NEXT_MOVE_SCORES_ASSESSMENT
      ) {
        this.update_assessment_state(
          MyNextMoveAssessmentStates.INTERESTS_SCORES
        );
      } else {
        this.update_assessment_state(
          MyNextMoveAssessmentStates.INTERESTS_QUESTIONS
        );
      }
    },
    // Function to check if interests scores assessment answer is updated or not
    is_interests_scores_ans_updates() {
      if (!this.assessment_scores_ans) return true;
      else
        return (
          this.next_move_assessment_scores.artistic !==
            this.assessment_scores_ans.artistic ||
          this.next_move_assessment_scores.conventional !==
            this.assessment_scores_ans.conventional ||
          this.next_move_assessment_scores.enterprising !==
            this.assessment_scores_ans.enterprising ||
          this.next_move_assessment_scores.investigative !==
            this.assessment_scores_ans.investigative ||
          this.next_move_assessment_scores.realistic !==
            this.assessment_scores_ans.realistic ||
          this.next_move_assessment_scores.social !==
            this.assessment_scores_ans.social
        );
    },
    // Onet MCQS answers submission processing
    async process_interest_question() {
      // API call to get onet result from backend
      this.set_data_loading(true);
      // Get all the answers and join them to form a string
      const result = this.interests_questions.results
        .map((obj: AssessmentQuestionnairesWithAns) => obj.answer)
        .join("");
      const onet_result_payload: OnetResultPayload = {
        result: result
      }; // Payload to send to backend to get onet result
      const resp: IOnetResults.Root | null = await this.fetch_onet_result(
        onet_result_payload
      );
      // If response is not received then set error and stop loading
      if (!resp) {
        this.set_data_error("Failed to get assessment result");
        this.set_data_loading(false);
        return;
      }
      // If response is received then create result in the backed
      const assessment_saved = await this.save_assessment_result(resp);
      if (!assessment_saved) return;
      this.update_assessment_state(MyNextMoveAssessmentStates.RESULTS);
      this.set_data_loading(false);
    },
    // Function to save assessment result in DB
    async save_assessment_result(result: IOnetResults.Root) {
      const assessment_result_payload: AssessmentResultApiPayload = {
        assessment_id: this.next_move_selected_assessment.id as number,
        status: IAppAssessment.Result.COMPLETED,
        user_id: this.get_user.id,
        result: result
      };
      const assesment_result_resp = await this.assessment_result(
        assessment_result_payload
      );
      if (!assesment_result_resp) {
        this.set_data_error("Failed to save assessment result");
        this.set_data_loading(false);
        return false;
      }

      // If response is received then set assessment result & move to results state
      this.set_selected_assessment_result(assesment_result_resp);
      return true;
    }
  }
});
