













































































































































import { APP_ASSESSMENTS } from "@/store/modules/common/constants";
import {
  NEXT_MOVE_DATA_ERROR,
  NEXT_MOVE_DATA_LOADING,
  NEXT_MOVE_SELECTED_ASSESSMENT_RESULT,
  NEXT_MOVE_SELECTED_INTEREST_AREA,
  NEXT_MOVE_SELECTED_JOB_ZONE,
  RESET_MY_NEXT_MOVE_STATE
} from "@/store/modules/my_next_move/constants";
import Vue from "vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import NextMoveLoading from "../NextMoveLoading.vue";
import AppResourseNotFound from "@/components/shared/AppResourseNotFound.vue";
import NextMoveCareerList from "@/components/candidate/app_assessments/my_next_move/careers/NextMoveCareerList.vue";
import { OnetInterestAreaPayload } from "@/store/modules/candidates/interfaces";
import { ONET_INTEREST } from "@/store/modules/candidates/constants";
import {
  InterestAreas,
  OnetResultResponseData
} from "@/store/modules/my_next_move/interfaces";

export default Vue.extend({
  components: { NextMoveLoading, AppResourseNotFound, NextMoveCareerList },
  name: "NextMoveMoreCareers",
  data() {
    return {
      title: "Careers that fit your Interests and Prepration level",
      job_zones: [
        {
          text: "Job Zone One ",
          sub_text: "(Little or No Job Prepration Needed)",
          value: 1
        },
        {
          text: "Job Zone Two ",
          sub_text: "(Some Prepration Need)",
          value: 2
        },
        {
          text: "Job Zone Third ",
          sub_text: "(Medium Prepration Need)",
          value: 3
        },
        {
          text: "Job Zone Fourth ",
          sub_text: "(High Prepration Need)",
          value: 4
        },
        {
          text: "Job Zone Fifth ",
          sub_text: "(Extensive Prepration Need)",
          value: 5
        }
      ],
      interest_areas: [
        {
          text: "Realistic",
          value: "Realistic"
        },
        {
          text: "Investigative",
          value: "Investigative"
        },
        {
          text: "Artistic",
          value: "Artistic"
        },
        {
          text: "Social",
          value: "Social"
        },
        {
          text: "Enterprising",
          value: "Enterprising"
        },
        {
          text: "Conventional",
          value: "Conventional"
        }
      ],
      dropdown_icon: require("@/assets/icons/linear/direction-down.svg"),
      flag_icon: require("@/assets/icons/linear/flag.svg"),
      onet_interest_data: [] as OnetResultResponseData[]
    };
  },
  computed: {
    ...mapGetters("my_next_move", {
      data_loading: NEXT_MOVE_DATA_LOADING,
      data_error: NEXT_MOVE_DATA_ERROR,
      selected_job_zone: NEXT_MOVE_SELECTED_JOB_ZONE,
      selected_assessment_result: NEXT_MOVE_SELECTED_ASSESSMENT_RESULT,
      selected_interest_area: NEXT_MOVE_SELECTED_INTEREST_AREA
    }),
    ...mapGetters("common", {
      app_assessments: APP_ASSESSMENTS
    })
  },
  watch: {
    selected_job_zone() {
      this.process_init();
    },
    selected_interest_area() {
      this.process_init();
    }
  },
  async created() {
    await this.process_init();
  },

  methods: {
    ...mapMutations("my_next_move", {
      set_data_loading: NEXT_MOVE_DATA_LOADING,
      set_data_error: NEXT_MOVE_DATA_ERROR,
      set_selected_job_zone: NEXT_MOVE_SELECTED_JOB_ZONE,
      reset_next_move_state: RESET_MY_NEXT_MOVE_STATE,
      set_selected_interest_area: NEXT_MOVE_SELECTED_INTEREST_AREA
    }),
    ...mapActions("candidate", {
      fetch_onet_interest: ONET_INTEREST
    }),
    async process_init() {
      this.set_data_loading(true);
      await this.fetch_interest_area();
      this.set_data_loading(false);
    },
    // Fetch interest area data from API
    async fetch_interest_area() {
      // Payload for API call
      const payload: OnetInterestAreaPayload = {
        area: this.selected_interest_area,
        zone: this.selected_job_zone
      };
      const resp = await this.fetch_onet_interest(payload); // API call
      if (resp) {
        // Check if data is empty or not
        if (resp.data.length) {
          this.onet_interest_data = resp.data;
          this.set_data_error(""); // Reset error message
        } else {
          this.set_data_error("No career found for this interest area"); // Set error message
        }
      } else {
        this.set_data_error("Error while fetching interest area");
      }
    },
    // Reset the process to initial state and fetch data again
    async reset_process() {
      this.set_selected_job_zone(1);
      this.set_selected_interest_area(InterestAreas.REALISTIC);
      await this.process_init();
    }
  }
});
