


























import { IAppAssessment, INextMove } from "@/interfaces";
import {
  NEXT_MOVE_ASSESSMENT_STATE,
  NEXT_MOVE_DATA_LOADING,
  NEXT_MOVE_SELECTED_ASSESSMENT,
  NEXT_MOVE_SELECTED_ASSESSMENT_RESULT,
  NEXT_MOVE_SELECTED_JOB_ZONE,
  RESET_MY_NEXT_MOVE_STATE
} from "@/store/modules/my_next_move/constants";
import { MyNextMoveAssessmentStates } from "@/store/modules/my_next_move/interfaces";
import Vue from "vue";
import { mapGetters, mapMutations } from "vuex";

export default Vue.extend({
  name: "NextMoveHeader",
  data() {
    return {
      headers: [] as INextMove.HeaderData[]
    };
  },
  computed: {
    ...mapGetters("my_next_move", {
      next_move_assessment_state: NEXT_MOVE_ASSESSMENT_STATE,
      next_move_selected_assessment_result:
        NEXT_MOVE_SELECTED_ASSESSMENT_RESULT,
      next_move_selected_assessment: NEXT_MOVE_SELECTED_ASSESSMENT,
      next_move_selected_job_zone: NEXT_MOVE_SELECTED_JOB_ZONE,
      next_move_loading: NEXT_MOVE_DATA_LOADING
    })
  },
  created() {
    this.set_headers();
  },
  methods: {
    ...mapMutations("my_next_move", {
      reset_next_move_state: RESET_MY_NEXT_MOVE_STATE,
      set_next_move_state: NEXT_MOVE_ASSESSMENT_STATE,
      set_job_zones: NEXT_MOVE_SELECTED_JOB_ZONE
    }),
    set_headers() {
      this.headers = [
        {
          title: "Start",
          state: [
            MyNextMoveAssessmentStates.START,
            MyNextMoveAssessmentStates.START_DESCRIPTION
          ]
        },
        {
          title: "Interests",
          state: [
            MyNextMoveAssessmentStates.INTERESTS_SCORES,
            MyNextMoveAssessmentStates.INTERESTS_QUESTIONS
          ]
        },
        {
          title: "Results",
          state: [MyNextMoveAssessmentStates.RESULTS]
        },
        {
          title: "Job Zones",
          state: [MyNextMoveAssessmentStates.JOB_ZONES]
        },
        {
          title: "Careers",
          state: [MyNextMoveAssessmentStates.CAREERS]
        }
      ];
    },
    process_state_click(index: number) {
      switch (this.headers[index].title) {
        case "Start":
          this.reset_next_move_state();
          break;
        case "Interests":
          if (
            this.next_move_selected_assessment &&
            this.next_move_selected_assessment.assessment_type ===
              IAppAssessment.List.MY_NEXT_MOVE_SCORES_ASSESSMENT
          )
            this.set_next_move_state(
              MyNextMoveAssessmentStates.INTERESTS_SCORES
            );
          else if (
            this.next_move_selected_assessment &&
            this.next_move_selected_assessment.assessment_type ===
              IAppAssessment.List.MY_NEXT_MOVE_ASSESSMENT
          )
            this.set_next_move_state(
              MyNextMoveAssessmentStates.INTERESTS_QUESTIONS
            );
          break;
        case "Results":
          this.set_next_move_state(MyNextMoveAssessmentStates.RESULTS);
          break;
        case "Job Zones":
          this.set_next_move_state(MyNextMoveAssessmentStates.JOB_ZONES);
          break;
        case "Careers":
          if (!this.next_move_selected_job_zone) this.set_job_zones(1);
          this.set_next_move_state(MyNextMoveAssessmentStates.CAREERS);
          break;
        default:
          this.reset_next_move_state();
          break;
      }
    }
  }
});
