















































import { NEXT_MOVE_ASSESSMENT_STATE } from "@/store/modules/my_next_move/constants";
import { MyNextMoveAssessmentStates } from "@/store/modules/my_next_move/interfaces";
import Vue from "vue";
import { mapMutations } from "vuex";
export default Vue.extend({
  name: "NextMoveStart",
  data() {
    return {
      title: "Welcome to the HCMS Interest Profiler!"
    };
  },
  methods: {
    ...mapMutations("my_next_move", {
      update_next_move_assessment_state: NEXT_MOVE_ASSESSMENT_STATE
    }),
    update_state() {
      this.update_next_move_assessment_state(
        MyNextMoveAssessmentStates.INTERESTS_SCORES
      );
    }
  }
});
