



































import NextMoveCareersNotFound from "@/components/candidate/app_assessments/my_next_move/careers/NextMoveCareersNotFound.vue";
import { OnetResultResponseData } from "@/store/modules/my_next_move/interfaces";

import Vue from "vue";
export default Vue.extend({
  name: "NextMoveCareerList",
  components: { NextMoveCareersNotFound },
  props: {
    data: {
      type: Array as () => Array<OnetResultResponseData>,
      required: true
    }
  },
  methods: {
    on_job_click(jid: string) {
      sessionStorage.setItem("job_id_next_move_assessment", jid);
      this.$router.push({ path: "/candidate/jobs/view/" + jid });
    }
  }
});
