






















































































































































































import { IAppAssessment } from "@/interfaces";
import { ScoreFormData } from "@/interfaces/candidate/my_next_move";
import { APP_ASSESSMENTS } from "@/store/modules/common/constants";
import {
  NEXT_MOVE_ASSESSMENT_SCORES,
  NEXT_MOVE_DATA_ERROR,
  NEXT_MOVE_DATA_LOADING,
  NEXT_MOVE_SCORES_ASSESSMENT_ANS,
  NEXT_MOVE_SELECTED_ASSESSMENT,
  NEXT_MOVE_SELECTED_ASSESSMENT_QUESTIONS,
  NEXT_MOVE_SELECTED_ASSESSMENT_RESULT
} from "@/store/modules/my_next_move/constants";
import { get_color_by_name } from "@/utils/colors";
import { parseStrToInt } from "@/utils/global";
import Vue from "vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import NextMoveLoading from "../NextMoveLoading.vue";
import AppResourseNotFound from "@/components/shared/AppResourseNotFound.vue";
import {
  APP_ASSESSMENT_ANSWERS,
  APP_ASSESSMENT_QUESTIONNAIRES,
  SUBMIT_APP_ASSESSMENT_ANSWERS
} from "@/store/modules/candidates/constants";
import { FetchAssessmentAnswersApiPayload } from "@/store/modules/candidates/interfaces";
import { GET_USER_DETAILS } from "@/store/modules/auth/constants";
import { GET_ASSESSMENT_RESULT } from "@/store/modules/recruiter/constants";
import { GetAssessmentResultApiPayload } from "@/store/modules/recruiter/interfaces";
export default Vue.extend({
  components: { NextMoveLoading, AppResourseNotFound },
  name: "NextMoveInterestsScores",
  data() {
    return {
      title: "Enter your scores",
      scores_form: false,
      form_fields: [] as ScoreFormData[]
    };
  },
  created() {
    this.set_score_form_data();
  },
  async mounted() {
    await this.process_init();
  },
  computed: {
    ...mapGetters("my_next_move", {
      next_move_assessment_scores: NEXT_MOVE_ASSESSMENT_SCORES,
      data_loading: NEXT_MOVE_DATA_LOADING,
      data_error: NEXT_MOVE_DATA_ERROR,
      selected_assessment: NEXT_MOVE_SELECTED_ASSESSMENT,
      selected_assessment_questions: NEXT_MOVE_SELECTED_ASSESSMENT_QUESTIONS,
      assessment_scores_ans: NEXT_MOVE_SCORES_ASSESSMENT_ANS
    }),
    ...mapGetters("common", {
      app_assessments: APP_ASSESSMENTS
    }),
    ...mapGetters("auth", {
      get_user_details: GET_USER_DETAILS
    })
  },
  methods: {
    ...mapMutations("my_next_move", {
      set_selected_assessment: NEXT_MOVE_SELECTED_ASSESSMENT,
      set_selected_assessment_questions:
        NEXT_MOVE_SELECTED_ASSESSMENT_QUESTIONS,
      set_data_loading: NEXT_MOVE_DATA_LOADING,
      set_data_error: NEXT_MOVE_DATA_ERROR,
      set_assessment_scores: NEXT_MOVE_ASSESSMENT_SCORES,
      set_assessment_scores_ans: NEXT_MOVE_SCORES_ASSESSMENT_ANS,
      set_selected_assessment_result: NEXT_MOVE_SELECTED_ASSESSMENT_RESULT
    }),
    ...mapActions("candidate", {
      fetch_assessment_questionnaries: APP_ASSESSMENT_QUESTIONNAIRES,
      submit_assessment_answers: SUBMIT_APP_ASSESSMENT_ANSWERS,
      fetch_assessment_answers: APP_ASSESSMENT_ANSWERS
    }),
    ...mapActions("recruiter", {
      get_assessment_result: GET_ASSESSMENT_RESULT
    }),
    // Set form fields data for scores form
    set_score_form_data() {
      this.form_fields = [
        {
          label: this.$t("my-next-move.interests.realistic").toString(),
          color: get_color_by_name("success"),
          model: this.next_move_assessment_scores.realistic,
          placeholder: this.$t("my-next-move.interests.realistic").toString(),
          field_rules: [
            (value: string) => {
              const isNum = parseStrToInt(value);
              if (isNum === null) return this.$t("rules.next-move.number");
              else if (isNum >= 0 && isNum <= 40) return true;
              else return this.$t("rules.next-move.number");
            }
          ]
        },
        {
          label: this.$t("my-next-move.interests.investigative").toString(),
          color: get_color_by_name("danger"),
          model: this.next_move_assessment_scores.investigative,
          placeholder: this.$t(
            "my-next-move.interests.investigative"
          ).toString(),
          field_rules: [
            (value: string) => {
              const isNum = parseStrToInt(value);
              if (isNum === null) return this.$t("rules.next-move.number");
              else if (isNum >= 0 && isNum <= 40) return true;
              else return this.$t("rules.next-move.number");
            }
          ]
        },
        {
          label: this.$t("my-next-move.interests.artistic").toString(),
          color: get_color_by_name("danger-light"),
          model: this.next_move_assessment_scores.artistic,
          placeholder: this.$t("my-next-move.interests.artistic").toString(),
          field_rules: [
            (value: string) => {
              const isNum = parseStrToInt(value);
              if (isNum === null) return this.$t("rules.next-move.number");
              else if (isNum >= 0 && isNum <= 40) return true;
              else return this.$t("rules.next-move.number");
            }
          ]
        },
        {
          label: this.$t("my-next-move.interests.social").toString(),
          color: get_color_by_name("warning"),
          model: this.next_move_assessment_scores.social,
          placeholder: this.$t("my-next-move.interests.social").toString(),
          field_rules: [
            (value: string) => {
              const isNum = parseStrToInt(value);
              if (isNum === null) return this.$t("rules.next-move.number");
              else if (isNum >= 0 && isNum <= 40) return true;
              else return this.$t("rules.next-move.number");
            }
          ]
        },
        {
          label: this.$t("my-next-move.interests.enterprising").toString(),
          color: get_color_by_name("primary"),
          model: this.next_move_assessment_scores.enterprising,
          placeholder: this.$t(
            "my-next-move.interests.enterprising"
          ).toString(),
          field_rules: [
            (value: string) => {
              const isNum = parseStrToInt(value);
              if (isNum === null) return this.$t("rules.next-move.number");
              else if (isNum >= 0 && isNum <= 40) return true;
              else return this.$t("rules.next-move.number");
            }
          ]
        },
        {
          label: this.$t("my-next-move.interests.conventional").toString(),
          color: get_color_by_name("primary-light"),
          model: this.next_move_assessment_scores.conventional,
          placeholder: this.$t(
            "my-next-move.interests.conventional"
          ).toString(),
          field_rules: [
            (value: string) => {
              const isNum = parseStrToInt(value);
              if (isNum === null) return this.$t("rules.next-move.number");
              else if (isNum >= 0 && isNum <= 40) return true;
              else return this.$t("rules.next-move.number");
            }
          ]
        }
      ];
    },
    // Process init
    async process_init() {
      if (this.check_data_existence()) return;
      this.set_data_loading(true); // Loading until data is fetched
      this.set_data_error(""); // Reset error
      const assessment = this.app_assessments.find(
        (val: IAppAssessment.Assessment) =>
          val.assessment_type ===
          IAppAssessment.List.MY_NEXT_MOVE_SCORES_ASSESSMENT
      );
      if (!assessment) {
        this.set_data_error("Assessment not found");
        this.set_data_loading(false);
        return;
      }
      this.set_selected_assessment(assessment);
      // Fetching questionaries
      const response = await this.fetch_assessment_questionnaries({
        assessment_id: assessment.id
      });
      // Checking if response is valid or not
      // If not valid then setting error message
      if (!response || response.length <= 0) {
        this.set_data_error("Failed to get assessment record");
        this.set_data_loading(false);
        return;
      }
      // Setting selected assessment questions
      this.set_selected_assessment_questions(response);
      // Fetching answer
      const ans_payload: FetchAssessmentAnswersApiPayload = {
        assessment_id: assessment.id,
        question_id: response[0].id,
        user_id: this.get_user_details.id,
        page: 0,
        limit: 10
      };
      const ans_response = await this.fetch_assessment_answers(ans_payload);
      if (ans_response && ans_response.results.length > 0) {
        const scores = JSON.parse(ans_response.results[0].answer);
        this.set_assessment_scores(scores);
        this.set_assessment_scores_ans(
          JSON.parse(ans_response.results[0].answer)
        );
      }
      // Fetch assessment result
      const assessment_result_payload: GetAssessmentResultApiPayload = {
        assessment_id: assessment.id
      };
      const assessment_result = await this.get_assessment_result(
        assessment_result_payload
      );
      this.set_selected_assessment_result(assessment_result);
      this.set_data_loading(false);
    },
    /**
     * If assessment is selected and have my next move scores assessment answer_type
     * and assessment questions are fetched and assessment scores are set
     * then return true else false
     */
    check_data_existence() {
      if (
        this.selected_assessment &&
        this.selected_assessment.assessment_type ===
          IAppAssessment.List.MY_NEXT_MOVE_SCORES_ASSESSMENT &&
        this.selected_assessment_questions.length > 0 &&
        this.assessment_scores_ans
      )
        return true;
      return false;
    }
  }
});
