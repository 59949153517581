





















































































































































































































import InterestProfileJobDetails from "@/components/shared/my_next_move/InterestProfileJobDetails.vue";
import {
  NEXT_MOVE_JOB_ZONE_DETAILS,
  NEXT_MOVE_SELECTED_JOB_ZONE
} from "@/store/modules/my_next_move/constants";
import { JobZoneDetails } from "@/store/modules/my_next_move/interfaces";
import Vue from "vue";
import { mapGetters, mapMutations } from "vuex";
export default Vue.extend({
  components: { InterestProfileJobDetails },
  name: "NextMoveJobZones",
  data() {
    return {
      title: "Select A Job Zone",
      view_job: false
    };
  },
  computed: {
    ...mapGetters("my_next_move", {
      selected_job_zone: NEXT_MOVE_SELECTED_JOB_ZONE
    })
  },
  methods: {
    ...mapMutations("my_next_move", {
      set_selected_job_zone: NEXT_MOVE_SELECTED_JOB_ZONE,
      set_job_zone_title: NEXT_MOVE_JOB_ZONE_DETAILS
    }),
    view_job_zone_details(selected: number) {
      let title = "";
      switch (selected) {
        case 1:
          title = JobZoneDetails.LITTLE_NO_PREPARATION;
          break;
        case 2:
          title = JobZoneDetails.SOME_JOB_PREPERATION;
          break;
        case 3:
          title = JobZoneDetails.MEDIUM_JOB_PREPERATION;
          break;
        case 4:
          title = JobZoneDetails.HIGH_JOB_PREPERATION;
          break;
        case 5:
          title = JobZoneDetails.EXTENSIVE_JOB_PREPERATION;
          break;
      }
      this.set_job_zone_title(title);
      this.view_job = true;
    }
  }
});
